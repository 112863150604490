<template>
  <section id="edit-display-campaign">
    <b-row class="mb-5">
      <b-col md="10">
        <h1>{{ $t('adsDisplay.campaigns') }}</h1>
        <h5 class="text-primary">
          {{ $t("adsDisplay.campaignEdit") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end mr-1">
        <router-link to="/displayAdvertising/campaigns">
          <b-button variant="danger">
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-tabs pills>
      <!-- Resource -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("editContent.title2") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <edit-campaign-data-card
              v-show="mostrarDatos"
              :info="allDisplayCampaigns"
              :is-display-campaign="true"
              :campaign-dates-overlaps="datesOverlaps"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>{{ $t('fechas') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <edit-campaign-dates
              v-show="mostrarDatos"
              :info="allDisplayCampaigns"
              :is-display-campaign="true"
              :disabled-dates="disabledDates"
              @datesChanged="getData"
            />
          </b-col>
        </b-row>
      </b-tab>
      <!-- GEOBLOQUEO -->
      <b-tab v-if="geoipbloking">
        <template #title>
          <feather-icon icon="MapIcon" />
          <span>{{ $t('zones.title') }}</span>
        </template>
        <b-card>
          <geoblocking
            :type-element="'DISC'"
          />
        </b-card>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import {
  BButton, BRow, BCol, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import { messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import geoblocking from '@/views/components/geoblocking.vue'
import EditCampaignDataCard from '../../publicidad/campaigns/cards/EditCampaignDataCard.vue'
import EditCampaignDates from '../../publicidad/campaigns/cards/EditCampaignDates.vue'

export default {
  components: {
    BButton,
    BRow,
    geoblocking,
    BCol,
    EditCampaignDataCard,
    BCard,
    EditCampaignDates,
    BTabs,
    BTab,
  },
  data() {
    return {
      allDisplayCampaigns: null,
      internal: false,
      geoipbloking: false,
      userData: getUserData(),
      mostrarDatos: false,
      disabledDates: [],
      datesOverlaps: false,
    }
  },
  mounted() {
    this.geoipbloking = this.userData?.profile?.client?.geoipbloking
    this.getData()
  },
  methods: {
    getData() {
      const { id } = this.$route.params
      const query = `
          query {
            allDisplayCampaigns(id:"${id}",client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  name
                  description
                  isActive
                  startDate
                  finalDate
                  ads {
                    totalCount                  
                  }
                }
              }
            }
          }
        `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        if (res.data.data.allDisplayCampaigns.edges.length) {
          const campaigns = res.data.data.allDisplayCampaigns.edges[0].node
          this.mostrarDatos = true

          this.allDisplayCampaigns = campaigns
          this.getCampaignDates()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    showToast(type) {
      if (type === 1) {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('code.updateCamp'),
            icon: 'CoffeeIcon',
            variant: 'success',
            position: 'bottom-right',
          },
        })
      } else {
        this.$toast.error({
          component: ToastificationContent,
          position: 'bottom-right',
          closeOnClick: true,
          props: {
            title: this.$t('code.errorUpdate'),
            icon: 'fas fa-times',
            variant: 'error',
            position: 'bottom-right',
          },
        })
      }
    },
    getCampaignDates() {
      const query = `
            query{
                allDisplayCampaigns(client:"${this.userData.profile.client.id}", isActive:true) {
                  totalCount
                    edges {
                        node {
                            id
                            startDate
                            finalDate
                        }
                    }
                }
            }
            `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const campaigns = res.data.data.allDisplayCampaigns.edges

        const campaignDates = []
        campaigns.forEach(element => {
          if (this.allDisplayCampaigns.id !== element.node.id)campaignDates.push(element.node)
        })
        this.disabledDates = campaignDates.map(campaign => ({
          from: campaign.startDate.split('T')[0],
          to: campaign.finalDate.split('T')[0],
        }))
        this.checkOverlap()
      })
    },
    checkOverlap() {
      if (!this.allDisplayCampaigns) this.datesOverlaps = false
      const selectedStart = new Date(this.allDisplayCampaigns.startDate)
      const selectedEnd = new Date(this.allDisplayCampaigns.finalDate)
      this.datesOverlaps = this.disabledDates.some(({ from, to }) => {
        const rangeStart = new Date(from)
        const rangeEnd = new Date(to)

        // Comprobamos si las fechas de la campaña seleccionada coincide con alguna de las activas
        return (
          (selectedStart >= rangeStart && selectedStart <= rangeEnd)
          || (selectedEnd >= rangeStart && selectedEnd <= rangeEnd)
          || (selectedStart <= rangeStart && selectedEnd >= rangeEnd)
        )
      })
    },
  },
}
</script>
<style scoped>
#edit-campaign {}
</style>
